// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_R1l4N{background-color:var(--color-supportive-3-100);border-right:.0625rem solid var(--color-supportive-3-400);display:flex;flex-direction:column;height:100%;overflow:auto;width:100%}.menuItem_5zBfi{align-items:center;cursor:pointer;display:flex;flex-direction:row;margin-bottom:.5rem;padding:.5rem 1rem;transition:background-color var(--transition-duration-shortest) ease-in-out}.active_rp81S{border-left:.25rem solid var(--color-purple-500);padding-left:.75rem}.active_rp81S,.menuItem_5zBfi:hover{background-color:var(--color-supportive-3-200)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_R1l4N",
	"menuItem": "menuItem_5zBfi",
	"active": "active_rp81S"
};
module.exports = ___CSS_LOADER_EXPORT___;
